<template>
  <section id="reset-password" class="mt-14">
    <auth-forms-wrapper width="448px" height="" #default>
      <div id="reset-password-wrapper">
        <reset-password-form
          ref="form"
          :title="displayMessages.title"
          :on-submit-handler="onSubmitHandler"
          :is-loading="isLoading"
          btn-text="Reset Password"
          :display-messages="displayMessages"
          :is-form-disabled="!isOobCodeExists"
        />
      </div>
    </auth-forms-wrapper>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { AuthFormsWrapper } from "@/components/shared";
import { ResetPasswordForm } from "@/components/forms";
import {
  verifyPasswordResetTokenOrOobCode,
  confirmOobCodeAndResetPassword,
} from "@/services";

/** Expired token auth codes */
const EXPIRED_TOKEN_CODE = [
  "auth/invalid-action-code",
  "auth/expired-action-code",
];

/**
 * Reset Password view
 */
export default {
  name: "ResetPassword",
  /**
   * ---------------- Components ----------------
   */
  components: {
    AuthFormsWrapper,
    ResetPasswordForm,
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isLoading: false,
    };
  },
  /**
   * ---------------- Computed properties ----------------
   */
  computed: {
    /**
     * Error messages
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      const {
        form,
        errorMessages,
        successMessages,
      } = this.$dictionary.app.resetPassword;
      return { ...form, ...errorMessages, ...successMessages };
    },
    /**
     * Determines whether oobCode exists
     * @description oobCode is a firebase token which is used to reset user password
     * @type {Boolean}
     */
    isOobCodeExists() {
      return !!this.$route?.query?.oobCode;
    },
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Handles form submition and reset the user new password
     */
    async onSubmitHandler(formData) {
      // Stops execution of function if oobCode is not present
      if (!this.isOobCodeExists) return;
      try {
        this.isLoading = true;

        /** Checks if the oobCode is valid */
        const isValid = await verifyPasswordResetTokenOrOobCode(
          this.$route?.query?.oobCode
        );

        if (isValid) {
          await confirmOobCodeAndResetPassword(
            this.$route?.query?.oobCode,
            formData.confirmPassword
          );

          this.setSnackbar({
            type: this.$appConfig.snackbar.snackbarTypes.success,
            message: this.displayMessages.passwordChanged,
            value: true,
          });

          // Delays and redirects the user to login page
          const timeoutRef = setTimeout(() => {
            this.$router.replace(this.$paths.login);
            clearTimeout(timeoutRef);
          }, 2000);
        }
      } catch (error) {
        this.exceptionHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Handles all the api exceptions and display error messages
     */
    exceptionHandler(error) {
      let { code, message } = error;

      if (code) {
        if (code === "auth/weak-password") {
          message = this.displayMessages.weakPassword;
        } else if (EXPIRED_TOKEN_CODE.includes(code)) {
          message = this.displayMessages.linkTokenExpired;
        } else if (code === "auth/user-disabled") {
          message = this.displayMessages.accountDisabled;
        }
      }

      // Shows error messsage
      message &&
        this.setSnackbar({
          value: true,
          message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin: 0 !important  ;
}
</style>
